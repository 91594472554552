// .dropdown:hover .dropdown-menu{ display: block;}
.dropdown-arrow-none {
  &.dropdown-toggle::after {
    display: none;
  }
}
.dropdown-menu {
  max-width: 138px;
  min-width: inherit;
  width: 138px;
  margin-top: 0px !important;
  box-shadow: 0px 3px 6px #0000001a;
  border: 1px solid #e9e9e9;
  border-radius: 15px;
  padding: 15px 20px 10px !important;

  li:hover {
    background-color: transparent !important;
  }

  a {
    font-family: "barlow_condensedmedium";
    font-size: 22px;    
    color: rgba(0, 0, 0, 0.8);
    background-color: transparent !important;
    padding: 7px 0;

    &:hover{
      color: $color-primary !important;
    }
  }
}
