/****** Responsive Media Queries ******/
// $xs: '479px';
// $sm: '576px';
// $mobile-landscape: '767px';
// $md: '768px';
// $lg: '992px';
// $xl: '1200px';
// $xxl: '1400px'
.modal-dialog {
  padding-left: 15px;
  padding-right: 15px;
}
.modal-dialog-820 {
  max-width: 820px;
}
.modal-dialog-1064 {
  max-width: 1064px;
}
.modal-dialog-485 {
  max-width: 485px;
}
.modal-backdrop.show {
  opacity: 0.7;
}

.modal-content {
  box-shadow: 0px 3px 6px #0000001a;
  border: 1px solid #e9e9e9;
  border-radius: 15px;
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}
.offcanvas_common .offcanvas-header {
  padding: 20px 30px;
}
.offcanvas_common .offcanvas-body {
  padding: 20px 30px;
}
.offcanvas_common .offcanvas-footer {
  padding: 20px 30px;

  .btn {
    text-transform: none;
  }
}

.offcanvas .btn-close-design2 {
  position: absolute;
  left: -92px;
  opacity: 1;
  background: #fff;
  border-radius: 50px 0 0 50px;
  height: 50px;
  width: 92px;
  color: $color-default;
  padding: 0;
  top: 10px;
}
.offcanvas .btn-close-design2 svg {
  fill: #fff;
}
.offcanvas .btn-close-design2.bg-white svg {
  fill: #2d2d2d;
}

.offcanvas .btn-close-design2.bg-white svg {
  fill: #2d2d2d;
}

.form-check {
  display: inline-flex;
}

.btn-close-outside {
  position: absolute;
  right: -62px;
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-size: 14px;
}

.btn-close-fullscreen {
  position: fixed !important;
  background-color: #fff;
  right: 0;
  z-index: 2;
  opacity: 1;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-size: 12px !important;
  right: 15px;
  top: 15px;
}

.btn-close-2 {
  position: absolute;
  right: -52px;
  z-index: 9;
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-size: 12px;
}

@media (min-width: 1200px) {
  .btn-close-desktopHideOnly {
    display: none;
  }
}
@include media($lg) {
  .btn-close-outside {
    bottom: -53px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .btn-close-2 {
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -56px;
    top: inherit;
  }
}

@include media($md) {
  .modal-dialog-820 {
    max-width: calc(100% - 50px);
  }
}

@include media($mobile-landscape) {
  .modal-dialog {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .offcanvas {
    border: none !important;
  }
  .offcanvas_common {
    .offcanvas-header {
      padding: 10px 20px;
    }
    .offcanvas-body {
      padding: 20px 20px;
    }
    .offcanvas-footer {
      padding: 15px 20px;
    }
  }
  .offcanvas .btn-close-design2 {
    right: 5px;
    left: inherit;
    width: 40px;
    height: 40px;
    top: 4px;
    text-align: right;
    display: flex;
  }
  .offcanvas-footer {
    .btn {
      height: 38px !important;
      font-size: 14px !important;
    }
  }
  .modal-content {
    border-radius: 10px;
  }
  .btn-close-fullscreen {
    width: 20px;
    height: 20px;
    background-size: 9px !important;
    right: 10px;
    top: 10px;
  }
  .modal-dialog-popup {
    margin-top: 0 !important;
  }
  .btn-close-2{ bottom: -44px !important;}
}

@include media($xs) {
  .modal-dialog-820 {
    max-width: inherit;
  }
}
.modal-backdrop.show {
  opacity: 0.4;
}
