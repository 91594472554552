/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 1, 2023 */


@font-face {
    font-family: 'google_sansbold';
    src: url('googlesans-bold.woff2') format('woff2'),
         url('googlesans-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansbold_italic';
    src: url('googlesans-bolditalic.woff2') format('woff2'),
         url('googlesans-bolditalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


/* 
@font-face {
     font-family: 'gotham_narrowbook';
     src: url('gothamnarrow-book-webfont.woff2') format('woff2'),
          url('gothamnarrow-book-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 
 } */



@font-face {
    font-family: 'google_sansitalic';
    src: url('googlesans-italic.woff2') format('woff2'),
         url('googlesans-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansmedium';
    src: url('googlesans-medium.woff2') format('woff2'),
         url('googlesans-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansmedium_italic';
    src: url('googlesans-mediumitalic.woff2') format('woff2'),
         url('googlesans-mediumitalic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'google_sansregular';
    src: url('googlesans-regular.woff2') format('woff2'),
         url('googlesans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
     font-family: 'namdhinggobold';
     src: url('namdhinggo-bold-webfont.woff2') format('woff2'),
          url('namdhinggo-bold-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 
 }
 
 
 
 
 @font-face {
     font-family: 'namdhinggoextrabold';
     src: url('namdhinggo-extrabold-webfont.woff2') format('woff2'),
          url('namdhinggo-extrabold-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 
 }
 
 
 
 
 @font-face {
     font-family: 'namdhinggomedium';
     src: url('namdhinggo-medium-webfont.woff2') format('woff2'),
          url('namdhinggo-medium-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 
 }
 
 
 
 
 @font-face {
     font-family: 'namdhinggoregular';
     src: url('namdhinggo-regular-webfont.woff2') format('woff2'),
          url('namdhinggo-regular-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 
 }
 
 
 
 
 @font-face {
     font-family: 'namdhinggosemibold';
     src: url('namdhinggo-semibold-webfont.woff2') format('woff2'),
          url('namdhinggo-semibold-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 
 }

 @font-face {
     font-family: 'tuckersswash';
     src: url('tuckers_swash-webfont.woff2') format('woff2'),
          url('tuckers_swash-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 
 }
 
 
 
 
 @font-face {
     font-family: 'tuckersregular';
     src: url('tuckers-webfont.woff2') format('woff2'),
          url('tuckers-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 
 }