/* TYPOGRAPHY */

// font families
$font-primary: "google_sansregular";
$font-primary-bold: "google_sansbold";
$font-medium: "google_sansmedium";
$font-primary-bold-italic: "google_sansbold_italic";
$font-primary-italic: "google_sansitalic";
// $font-semibold: "google_sansmedium";
// $font-light: "barlow_condensedlight";
$font-medium-italic: "google_sansmedium_italic";

$font-3-regular: "tuckersregular";
$font-3-wash: "tuckersswash";
// $font-3-bold: "gothambold";
// $font-3-semibold: "barlow_condensedlight";
// $font-3-italic: "google_sansitalic";


$font-secondary: "namdhinggoregular";
$font-secondary-medium: "namdhinggomedium";
$font-secondary-bold: "namdhinggobold";
$font-secondary-black: "namdhinggoextrabold";
$font-secondary-samibold: "namdhinggosemibold";
// $font-secondary-thin: "barlowthin";
// $font-secondary-light: "barlowlight";


.font-3-regular {
  font-family: $font-3-regular;
}
.font-3-wash {font-family: $font-3-wash !important;
}
// .font-3-bold {font-family: $font-3-bold;
// }
// .font-3-semibold{}
// .font-3-italic {font-family: $font-3-italic;
// }

.font-primary {
  font-family: $font-primary !important;
}
.font-medium {
  font-family: $font-medium;
}
.font-bold {
  font-family: $font-primary-bold;
}
// .font-semibold,
// .font-semi-bold {
//   font-family: $font-semibold;
// }
// .font-light {
//   font-family: $font-light;
// }


.font-primary-italic {
  font-family: $font-primary-italic;
}
.font-medium-italic {
  font-family: $font-medium-italic;
}
.font-bold-italic {
  font-family: $font-primary-bold-italic;
}

.font-secondary-black {
  font-family: $font-secondary-black;
}

.font-secondary {
  font-family: $font-secondary;
}
.font-secondary-medium {
  font-family: $font-secondary-medium;
}

.font-secondary-bold {
  font-family: $font-secondary-bold;
}
.font-secondary-semi-bold {
  font-family: $font-secondary-samibold !important;
}
// .font-secondary-tin {
//   font-family: $font-secondary-thin;
// }
// .font-secondary-light {
//   font-family: $font-secondary-light;
// }


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary-bold;
}

strong,
b {
  font-weight: normal;
  font-family: $font-primary-bold;
}
