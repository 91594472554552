.app_header {
  height: 120px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  z-index: 100;
  border-top: 8px solid $color-primary;

  .container {
    max-height: 105px;
    transition: all 0.5s ease;
  }
  .header_inner {
    background-color: #fff;
    border-radius: 277px 277px 277px 277px;
    color: $color-default;
    padding: 0 10px;
    height: 60px;
    position: relative;
    padding-right: 132px;
  }

  &.sticky {
    background-color: $color-secondary;

    .Applogo_col {
      width: 120px;
    }
  }
}

.HeaderSearch_backdrop {
  position: fixed;
  left: 0;
  z-index: 10;
  top: 188px;
  background: #000000;
  width: 100%;
  transition: all 0.5s ease;
  height: 100%;
  opacity: 0;
  display: none;
}

.navbar_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul {
    width: 50%;
    &:nth-child(1) {
      width: calc(50% - 40px);
    }
    &:nth-child(2) {
      width: calc(50% - 160px);
    }
  }
}

.header_menu {
  ul {
    display: flex;
    justify-content: space-between;
    li {
      a {
        position: relative;
        font-size: 18px;
        cursor: pointer;
        color: $color-default;
        font-family: $font-medium;
        margin: 0 1px;
        border-radius: 97px;
        padding: 8px 13px;

        &::after {
          content: "";
          position: absolute;
          left: calc(50% - 25px);
          bottom: -5px;
          width: 50px;
          height: 4px;
          background-color: transparent;
          border: none;
          opacity: 0;
          border-radius: 20px;
          transition: all 0.3s ease;
        }

        &:hover,
        &.active {
          background-color: $color-secondary;
          color: #fff;
          &::after {
            width: 100%;
            background-color: $color-secondary;
            left: 0;
            right: 0;
          }
        }
      }

      &.active {
        a {
          background-color: $color-secondary;
          color: #fff;
          &::after {
            width: 100%;
            background-color: $color-secondary;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
}
.giveBtn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 277px;
  height: 100%;
  width: 132px;
  min-width: inherit;
}

.Applogo_col {
  width: 178px;
  margin: 0 auto;
  position: absolute;
  transition: all 0.3s ease;
  left: 0;
  right: 0;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
#navbar-example3 {
  width: 100%;
}

.search_open_row {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  max-width: 1014px;
  background: #fff;
  top: 0;
  height: 188px;
  padding-top: 32px;
  transform: translateY(-100%);
  transition: all 0.5s ease;

  .search_inline {
    max-width: 476px;
    margin: 0 auto;
    input {
      font-size: 18px;
    }
  }
}

.searchBar_open {
  box-shadow: 0px 3px 6px #0000001a;
  height: 188px;
  .search_open_row {
    transform: translateY(0);
  }
  .HeaderSearch_backdrop {
    display: block;
    opacity: 0.3;
  }
}

.Close_menu_overlay {
  position: fixed;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 15;
  border: none;
  display: none;
  transition: all 0.5s ease;
}
#Close_menu_CTA {
  display: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  position: absolute;
  right: 10px;
  z-index: 8;
}

.list_institution_mbl,
#menu_mbl_CTA {
  display: none !important;
}

.app_header.sticky .header_inner {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1019607843);
}

.app_sticky_btn {
  position: fixed;
  top: 225px;
  right: -10px;
  z-index: 10;
  box-shadow: 0 0 3px 0 #fff !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.hader_topbar {
  height: 80px;
}

.hader_topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social_links2 {
  li {
    a {
      display: inline-flex;
      margin-left: 25px;
      svg {
        transition: all 0.3s ease;
        fill: #fff;
      }

      &:hover,
      &:focus,
      &:active {
        svg {
          fill: $color-primary;
        }
      }
    }
    &:first-child {
      a {
        margin-left: 0;
      }
    }
  }
}

.mobile-menu-button {
  border: solid 1px #000 !important;
  color: #fff !important;
  margin-right: 30px;
}

.mobile-show {
  display: none;
}

.mobile-nav-shop {
  display: none;
}

/*Responsive start*/
@media (max-width: 1350px) {
  .header_menu ul li a {
    font-size: 16px;
    padding: 8px 14px;
  }
}
@media (min-width: 1200px) {
  .app_header {
    &.scrolled {
      height: 85px;

      &.searchBar_open {
        height: 188px;
      }
    }
  }
}
@include media($lg) {
  .navbar_wrap {
    padding-right: 90px;
  }

  .navbar_wrap ul:nth-child(1) {
    width: calc(49% - 5px);
  }

  .navbar_wrap ul:nth-child(2) {
    width: calc(36% - 0px);
  }

  .app_header {
    .header_inner {
      padding: 0 5px;
      padding-right: 80px;
    }
    .giveBtn {
      width: 80px;
    }
  }

  .header_menu ul li a {
    font-size: 13px;
    padding: 8px 8px;
  }
}

@include media($md) {
  .navbar_wrap {
    padding-right: 0px;
  }
  #app_Mainheader .Applogo_col + div .search_inline {
    position: absolute;
    left: 20px;
    background: #fff;
    bottom: 12px;
    width: calc(100% - 40px);
  }

  #Close_menu_CTA {
    display: block;
  }

  .hader_topbar {
    display: none;
  }

  /*Header Start*/
  .header_menu {
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 260px;
    height: 100%;
    z-index: 15;
    transition: all 0.5s ease;
    transform: translateX(-100%);
  }
  .app_header {
    height: 90px;
  }
  .app_header .container {
    max-height: 90px;
  }
  #menu_mbl_CTA {
    display: inline-flex !important;
  }
  .header_menu_inner {
    position: absolute;
    left: 0;
    top: 0;
    background: $color-secondary;
    height: 100%;
    overflow: auto;
    align-items: start !important;
    width: 260px;
    display: block !important;
    transition: all 0.5s ease;
  }
  .app_header .header_menu ul {
    display: block;
  }
  .app_header .header_menu ul li a {
    padding: 13px 0;
    font-size: 16px;
    display: flex;
  }
  .app_header .header_menu ul li a {
    color: #fff;

    &::after {
      display: none;
    }

    &.active {
      color: $color-secondary;
    }
  }
  .MenuOpen {
    .header_menu ul li a:hover,
    .header_menu ul li a.active {
      background: none !important;
      color: #fe6b02 !important;
    }
  }

  .header_menu_inner {
    padding-top: 15px;
  }

  .MenuOpen {
    .header_menu,
    .Close_menu_overlay {
      display: block !important;
    }
    .header_menu {
      transform: translateX(0);
    }
  }

  .app_header .search_open_row {
    height: 164px;
    z-index: 6;
    padding-top: 22px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .app_header .HeaderSearch_backdrop {
    top: 164px;
  }
  .app_header .search_open_row .search_inline input {
    font-size: 16px;
  }
  #Close_menu_CTA {
    display: block;
  }
  .navbar_wrap {
    flex-wrap: wrap !important;
  }
  .navbar_wrap ul:nth-child(1) {
    width: calc(100% - 50px);
    padding: 0px 0px 0px 20px;
  }

  .navbar_wrap ul:nth-child(2) {
    width: calc(100% - 50px);
    padding: 0px 0px 0px 20px;
  }

  .mobile-nav-shop {
    padding: 0px 0px 0px 20px !important;
    display: block;

    a {
      margin: 5px 15px 3px 0px !important;
    }
  }

  .app_header .container .Applogo_col img {
    max-width: 100px;
  }

  .sticky {
    .mobile-menu-button {
      svg {
        fill: #000 !important;
      }
    }
  }

  .mobile-show {
    display: block;
  }

  .m-btns {
    height: 38px !important;
    line-height: 38px !important;
    font-size: 14px !important;
    min-width: auto !important;
    padding: 0px 18px !important;
  }

  /*Header End*/
}

@include media($mobile-landscape) {
  .app_header {
    .header_inner {
      background: none;
      margin: 0px 0px 0px !important;
      border-radius: 0;
    }
  }

  .list_institution_mbl {
    display: block !important;
    margin: 10px 0 0 15px;
  }

  .app_header .container .Applogo_col img {
    max-width: 80px;
  }

  .app_header {
    height: 65px;
  }
  .app_header .container {
    max-height: 65px;
    padding-bottom: 0px;
  }

  .page-wrapper-v2 {
    padding-top: 115px;
  }
  .page-wrapper-v2 .btn.user_dropdown {
    height: 35px;
    svg {
      max-width: 18px;
    }
  }

  #menu_mbl_CTA {
    height: 30px;
    margin-top: 2px;
    padding: 6px !important;
    svg {
      max-width: 14px;
    }
  }

  .sticky {
    .mobile-menu-button {
      svg {
        fill: #fff !important;
      }
    }
  }

  .page-wrapper-v2 .btn.user_dropdown img {
    max-width: 21px;
  }
  .page-wrapper-v2 .dropdown-menu {
    max-width: 110px;
    min-width: inherit;
    width: 110px;
    border-radius: 10px;
    padding: 10px 15px 10px !important;
  }
  .page-wrapper-v2 .dropdown-menu a {
    font-size: 18px;
    padding: 5px 0;
  }
  .tab_wrap .tab button {
    margin: 0 6px;
  }
  .SearchClose_btn_col {
    font-size: 16px;
  }
  .app_header .search_open_row {
    height: 150px;
  }
  .app_header .HeaderSearch_backdrop {
    top: 150px;
  }
  .app_header .search_open_row {
    z-index: 20;
  }
  .app_header .search_open_row .search_inline {
    overflow: inherit;
  }
  .list_institution_desktop {
    display: none;
  }

  .app_header, .inner_page .app_header .btn {
    .btn {
      height: 30px !important;
      line-height: 30px !important;
      font-size: 14px !important;
      padding: 0 10px !important;
      min-width: inherit;
    }
  }
  .app_header .header_inner {
    padding: 0 50px 0 20px !important;
    height: 56px;
  }
  .mobile-nav-shop {
    display: flex;
    flex-direction: column;
    align-items: start;

    a {
      flex: 0 0 auto;
      width: auto;
    }
  }
}
