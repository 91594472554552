/*Base*/
@import "base/_reset.scss";
@import "../webfonts/stylesheet.css";
@import "base/_typography.scss";
@import "base/_variable.scss";

/*Mixins*/
@import "mixins/_mixins.scss";

/*Utilities*/
@import "base/_utilities.scss";

/*Module*/
@import "module/_form.scss";
@import "module/_buttons.scss";
@import "module/_modal.scss";
@import "module/_tabs.scss";
@import "module/_dropdown.scss";
@import "module/_listStyle.scss";
/*Common*/
@import "common/_header.scss";
@import "common/_footer.scss";

/*Pages*/
@import "pages/_home.scss";
@import "pages/_inerPages.scss";
@import "pages/_page.scss";