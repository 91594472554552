#siteFooter {
  padding-top: 90px;

  .social_links2 li a {
    margin-left: 35px;
  }
  .social_links2 li:first-child a{ margin-left: 0;}
  .Footerlogo_col {
  }

  .Footerlogo_col {
    margin-top: -90px;
    width: 178px;
    display: inline-block;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
}

.footer_link_wrap {
  border-radius: 30px 30px 0px 0px;
  padding: 16px 30px;

  a {
    padding: 10px 13px;
    font-size: 16px;
    color: $color-default;

    &:hover{ color: $color-primary;}
  }
}

/*Responsive start*/
@include media($md) {
  .footer_link_wrap {
    border-radius: 20px 20px 0px 0px;
    padding: 16px 5px;
  }

  .footer_link_wrap a {
    padding: 9px;
  }
}

@include media($mobile-landscape) {
  .footerLinks {
    font-size: 16px;
  }
  #fCol1 .font-32px {
    font-size: 20px !important;
    line-height: 26px;
  }
  .foot-social a {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .foot-social a i {
    font-size: 14px;
  }
  .footSocial_new a {
    margin-right: 8px !important;
  }
  .footCopy-col {
    flex-direction: column;
  }
  .footerLinks .mx-2 {
    margin-right: 0.3rem !important;
    margin-left: 0.3rem !important;
  }
  .Download_app_field .input-group-text.input-group-text-prepend {
    width: 46px;
  }
  #fCol1 .font-32 {
    font-size: 20px !important;
    line-height: 26px;
  }
  .social_iconList li {
    padding: 0 5px;
  }

  #siteFooter .Footerlogo_col {
    margin-top: -43px;
    width: 95px;
    display: inline-block;
  }
  .footer_link_wrap a {
    padding:8px 8px;
    font-size: 15px;
  }
  #siteFooter .social_links2 li a {
    margin-left: 19px;
  }
  #siteFooter {
    padding-top: 60px;
  }
  .footer_link_wrap { border-radius: 0px;}

  .footer_link_wrap {
    padding: 0px 10px 15px;
    flex-direction: column;
    width: 100%;
    align-items: start;
  }

  #siteFooter .btn {
    min-width: inherit;
  }

}
@include media($sm) {
// .footer_link_wrap{
//   padding: 16px 10px;
//     a {
//       padding:4px 8px;
//       font-size: 15px;
//     }
//   } 
}
