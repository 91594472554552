.list_app {
  li {
    position: relative;
    padding-left: 13px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 7px;
      width: 6px;
      height: 6px;
      display: inline-block;
      background: $color-primary;
      border-radius: 50%;
    }
  }

  &.list_app_li_bottom_margin {
    li:not(:last-child) {
      padding-bottom: 10px;
    }
  }

  &.list_app_tick {
    li {
      padding-left: 26px;
      line-height: 150%; margin-bottom: 15px;
      &::before {
        background: url(../img/check-circle.svg) center center no-repeat;
        width: 17px;
        height: 17px;
        top:5px;
      }
    }
  }
}
