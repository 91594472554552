.banner_section {
  // background: url(../img/bg_1.jpg) left top no-repeat;
  background-size: cover;
  border-radius: 0px 0px 0px 100px;
  min-height: 400px;
  padding-top: 0;

  &.banner_section_haveOverlay {
    padding-bottom: 190px;
  }
}
.banner_wrap {
  img {
    width: 100%;
  }
}
.banner_items_content {
  position: relative;
}
.banner_contents {
  position: absolute;
  left: 0;
  top: 200px;
  padding-bottom: 380px;
  width: 100%;
  height: calc(100% - 200px);
  display: flex;
  align-items: center;
}
.banner_btn {
  a:not(:first-child) {
    margin-left: 20px;
  }
}
.banner_mainHeading {
  font-size: 206px;
}
.banner_mainHeading_sub {
  position: relative;
}

.aboutImg {
  margin-top: -190px;
}
.solutions_img {
  height: 150px;
}
.bg_gredient_1 {
  background: transparent linear-gradient(128deg, #07438a 0%, #002755 100%) 0%
    0% no-repeat padding-box;
}

.features_box {
  background: transparent linear-gradient(126deg, #002755 0%, #003b81 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #2461a8;
  border-radius: 20px;
  padding: 33px;
}
.features_icon_col {
  height: 66px;
  img {
    max-height: 66px;
    width: auto;
    height: auto;
  }
}
.features_img_col {
  height: 250px;
  border: 1px solid #2f4f74;
  border-radius: 20px;
  overflow: hidden;
}

.tabContent_img {
  margin: -40px 0;
  img {
    max-height: 445px;
    width: auto;
    max-width: 100%;
    height: auto;
  }
}
.tabepane_row_content {
  align-items: center;

  > div:first-child {
    align-self: self-start;
  }
}

.testimonial_card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  height: 100%;
  .testimonial_card_img {
    height: 210px;
    overflow: hidden;
    @include border-radius(10px 10px 0px 0px);
  }

  .testimonial_card_body {
    padding: 0 20px 20px 20px;
    height: calc(100% - 210px);
  }
  .testimonial_card_logo {
    img {
      max-height: 80px;
      width: auto;
      height: auto;
    }
  }
  .testimonial_quote {
    margin-top: -30px;
    img {
      margin-left: -10px;
    }
  }
}

.Contact_section {
  background: url(../img/bg_2.jpg) left bottom no-repeat;
  background-size: cover;
  border-radius: 100px 0px 0px 0;
}

.contact_form {
  border-radius: 50px;
  padding: 60px 70px 50px;
}

.slick-arrow {
  width: 57px;
  height: 57px;
  /* UI Properties */
  background-color: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 0;
  border-radius: 50%;
  border: none;
  background: url(../img/left-arrow-svg.svg) center center no-repeat #fff;
  background-size: 12px;
  position: absolute;
  top: calc(50% - 30px);
  z-index: 9;
  transition: all 0.4s ease;

  &:hover {
    background: url(../img/left-arrow-svg-hover.svg) center center no-repeat
      $color-primary;
  }

  &.slick-prev {
    left: 20px;
  }

  &.slick-next {
    transform: rotate(180deg);
    right: 20px;box-shadow: 0px -3px 3px #00000029;
  }
}
.slick-slide {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
.layout-full-width2 {
  width: calc(((100vw - 100%) / 2) + calc(100% - 20px));
  box-sizing: border-box;
}
.slider_testimonial {
  position: relative;
  z-index: 2;
  .slick-track {
    margin-bottom: 30px;
    display: flex;
    .slick-slide {
      height: auto;
      margin: 0 15px;
    }
    .slick-list {
      margin: 0 -15px;
    }
  }
  .slider_testim_col {
    background-color: #fff;
  }
}
.slider_testimonial_bg_wrap {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 100px 0px 0px 0px;
    position: absolute;
    left: 15%;
    top: 0;
  }
}
.features_row {
  margin-left: -15px;
  margin-right: -15px;

  > div {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }
}

.tab_accordial_heading {
  display: none;
}
.video_section_site {
  max-width: 982px;
}
.video_container {
  margin-top: -346px;

  .slick-arrow {
    top: calc(50% - 62px);
  }

  .slick-prev {
    left: -26px;
  }

  .slick-next {
    right: -26px;
  }
}
.video_section_v1 {
  overflow: hidden;
  height: 556px;
  /* UI Properties */
  background: #fff;
  box-shadow: 0px 3px 13px #00000029;
  border: 8px solid #ffffff;
  border-radius: 20px;

  .imges-outer {
    border-radius: 20px;
  }
}
.video_section_caption {
  max-width: 632px;
  width: 100%;
  padding: 18px;
}
.video_section_title {
  border-radius: 20px 20px 0px 0px;
}
.video_item_title {
  border-radius: 0px 0px 20px 20px;
}
.Category_img {
  width: 160px;
  height: 160px;
  overflow: hidden;
}

.common_setion_gap {
  margin-bottom: 100px;
}

/*sharwan style start home page*/
.wlc_section_site {
  max-width: 100%;
  padding-left: 120px !important;

  h2 {
    margin-bottom: 25px;
  }

  .wlc_boxs {
    position: absolute;
    left: -150px;
    top: 28%;
    background: #fff;
    border-radius: 20px;
    padding: 63px 50px;

    h3 {
      line-height: 52px;
    }
  }
}
.wlc_matter {
  width: 100%;
  p {
    line-height: 30px;
    margin-bottom: 15px;
  }
}

.pad-top-100 {
  padding-top: 100px;
}

.upcoming-event-section {
  width: 100%;
  padding: 0px 75px;

  .event-bg {
    width: 100%;
    background: url(../img/event-bg.jpg) center center no-repeat;
    position: relative;
    height: 620px;

    img {
      height: 620px;
      object-fit: cover;
      border-radius: 20px;
    }
  }
}

.event-silder-box {
  width: 100%;
  margin-top: 35px;

  .event-box {
    width: 100%;
    background: #fff;
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 15px;

    .event-imgs {
      height: 230px;
      border-radius: 15px;
      object-fit: cover;
      margin-bottom: 10px;
    }

    h3 {
      width: 60%;
      line-height: 32px;
    }
  }

  .slick-prev {
    left: -75px;
  }

  .slick-next {
    right: -75px;
  }
}

.slick-slider {
  .slick-track {
    display: flex;
    margin-left: 0;
  }
  .slick-slide {
    height: auto !important;
  }
}

.upcoming-section-box {
  padding: 0px 50px;
  margin-top: -525px;
  position: relative;
  z-index: 2;
}

.divide-btns-bg {
  background: #fff;
  padding: 10px;
  margin-bottom: -35px;
}

.img-height-18px {
  height: 18px !important;
  width: auto !important;
}

.journey-section {
  width: 100%;
}

.width-985px {
  max-width: 100%;
  width: 985px !important;
}

.jounary-information {
  width: 100%;
  margin: 40px 0px 0px;

  .jounry-bg {
    position: relative;
    border-radius: 20px;
    margin: 0px 25px;

    img {
      height: 450px;
      object-fit: cover;
      border-radius: 20px;
    }

    .jounry-overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      background: transparent
        linear-gradient(180deg, #00000000 0%, #272c48 100%) 0% 0% no-repeat
        padding-box;
      border-radius: 20px;
      width: 100%;
      height: 100%;
      padding: 25px;
    }
  }

  .jounary-matter {
    position: absolute;
    bottom: 25px;
    padding-right: 25px;
  }
}

.contribution-section {
  width: 100%;

  .contribution-bg {
    background: #fffbf0;
    border: 1px solid #e1e1e1;
    border-radius: 20px;
    min-height: 428px;
    height: 100%;
    position: relative;
    text-align: center;
    padding: 0% 25%;

    &::before {
      content: "";
      position: absolute;
      left: 1px;
      top: 0px;
      background: url(../img/countri-img1.jpg) left top no-repeat;
      height: 320px;
      width: 378px;
      border-radius: 20px 0px 0px 0px;
    }

    &::after {
      content: "";
      position: absolute;
      right: 1px;
      bottom: 0px;
      background: url(../img/countri-img2.jpg) left top no-repeat;
      height: 298px;
      width: 444px;
      border-radius: 20px 0px 0px 0px;
    }
  }

  .country-content {
    z-index: 99;
    position: relative;
    margin-top: -100px;

    .contri-logo {
      img {
        height: 210px;
      }
    }
  }
}

.apostle-section {
  width: 100%;

  .apostle-bg {
    background: #272c48;
    padding: 0px 75px;
    width: 100%;
  }
}

.apostl-img {
  width: 100%;
  margin-top: -110px;

  img {
    margin-bottom: -3px;
  }
}

.top-space-100 {
  margin-top: 100px;
}

.apostl-matter {
  width: 100%;
  padding: 0px 50px 0px 30px;
}

.social_about li {
  a {
    border: solid 1px #fff;
    margin-right: 14px;
    width: 45px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    fill: #fff;

    &:hover {
      border: solid 1px #fe6b02;
      fill: #fe6b02;
    }
  }
}

.apostl-head {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 10px;

  &:after {
    content: "";
    width: 50px;
    height: 5px;
    background: #fe6b02;
    border-radius: 10px;
    position: absolute;
    bottom: 13px;
    left: 0px;
  }
}

.category-section {
  width: 100%;
}

/*sharwan style End home page*/

.event-box-img {
  overflow: hidden;
  border-radius: 15px;
  img {
    transition: all 0.4s ease;
  }
}
.upcoming-silde-2 {
  .event-box {
    transition: all 0.4s ease;
    &:hover {
      border-color: $color-primary;
      background: #fffbf0;

      .event-box-img {
        img {
          transform: scale(1.2);
        }
      }

      .link-primary .svgFill {
        fill: $color-primary-dark;
      }
    }
  }
  .event-box-link {
  }
}

.jounary-information {
  .jounry-bg {
    overflow: hidden;
    .jounry-bg-img {
      overflow: hidden;
      img {
        transition: all 0.4s ease;
      }
    }
    &:hover {
      .jounry-bg-img {
        img {
          transform: scale(1.2);
        }
      }
      .btn-primary {
        background-color: $color-primary-dark;
        border-color: $color-primary-dark;
      }
    }
  }
}

.Category_box {
  transition: all 0.4s ease;
  &:hover {
    border-color: $color-primary !important;background: #fffbf0;
    .link-primary .svgFill {
      fill:  $color-primary-dark;
  }
}
}

/*Responsive style start*/
@media (max-width: 1600px) {
  .home_banners {
    .banner_mainHeading {
      font-size: 168px;
      margin: 0px !important;
      padding: 0px !important;
    }
    .banner_btn {
      padding: 5px !important;
    }
  }

  .upcoming-event-section {
    padding: 0px 35px;
  }
  .upcoming-section-box {
    padding: 0px 100px;
  }

  .tabepane_row_content {
    display: flex !important;
  }
}

@include media($xxl) {
  .home_banners {
    top: 210px;
    padding-bottom: 380px;
    height: calc(100% - 180px);

    .banner_mainHeading_sub {
      font-size: 22px;
      bottom: -10px;
    }
  }

  .Applogo_col {
    width: 155px;
  }
  .video_section_caption {
    padding: 13px;
  }

  .wlc_section_site {
    .wlc_boxs {
      left: -125px;
      padding: 30px 35px;

      h3 {
        line-height: 45px;
      }
    }
  }
  .upcoming-event-section {
    padding: 0px 50px;
  }

  .upcoming-section-box {
    padding: 0px 110px;
  }

  .event-silder-box {
    .event-box {
      h3 {
        width: 100%;
        line-height: 30px;
      }
    }
  }
}

@include media($xl) {
  .video_section_v1 {
    height: 500px;
    .imges-outer {
      height: 500px;
    }
  }

  .video_container {
    margin-top: -300px;
  }

  .upcoming-section-box {
    padding: 0px 80px;
  }

  .slick-arrow {
    width: 48px;
    height: 48px;
    background-size: 10px;
  }
}

@media (max-width: 1350px) {
  .home_banners {
    .banner_mainHeading {
      font-size: 145px;
    }
  }

  .home_banners {
    top: 240px;
  }
  .video_section_v1 {
    height: 450px;

    .imges-outer {
      height: 450px;
    }
  }

  .video_container {
    margin-top: -270px;
  }

  .wlc_section_site {
    h2 {
      margin-bottom: 20px;
    }
  }
  .apostl-matter {
    padding: 0px 0px 0px 0px;
  }
  .apostl-head {
    padding-bottom: 30px;
    margin-bottom: 0px;
  }
}

@include media($lg) {
  .Applogo_col {
    width: 99px;
    left: 25px;
  }
  .app_header {
    height: 80px;

    .header_inner {
      padding-right: 0px;
      margin: 35px 0px 0px;
    }
  }

  .video_section_v1 {
    height: 350px;
    .imges-outer {
      height: 350px;
    }
  }

  .video_container {
    margin-top: -215px;
  }

  .hader_topbar {
    display: none;
  }

  .home_banners {
    .banner_mainHeading_sub {
      font-size: 16px;
      bottom: -5px;
    }
    .banner_mainHeading {
      font-size: 95px;
    }
  }

  .upcoming-event-section {
    .event-bg {
      height: 500px;

      img {
        height: 100%;
      }
    }
  }

  .wlc_section_site {
    padding-left: 0px !important;

    .wlc_boxs {
      left: 18px;
      padding: 15px;
      bottom: -55px;
      top: inherit;
      display: none;
    }

    .wlc_matter {
      padding: 15px 0px 0px;
    }
  }

  .upcoming-section-box {
    margin-top: -460px;
  }

  .pad-top-100 {
    padding-top: 65px;
  }

  .top-space-100 {
    margin-top: 65px;
  }

  .upcoming-event-section {
    padding: 0px 30px;
  }

  .contribution-section {
    margin-top: 90px !important;
  }

  .common_setion_gap {
    margin-bottom: 55px;
  }

  .banner_section.banner_section_haveOverlay {
    padding-bottom: 100px;
  }

  .apostle-section {
    .apostle-bg {
      padding: 0px 10px;
    }
  }

  .slider_testimonial_bg_wrap {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .slider_testimonial {
    .slick-arrow {
      bottom: -38px;
    }
  }
  .contact_form {
    padding: 40px 50px 40px;
  }
  .jounary-information {
    .jounry-bg {
      margin: 0px 10px;
    }
  }
  .apostle-section {
    margin-top: 100px !important;
  }
}

@include media($md) {
  .app_header {
    .header_inner {
      padding-right: 0px;
      margin: 45px 0px 0px;
      .justify-content-between {
        justify-content: start !important;
      }
    }
  }

  .apostl-matter {
    padding: 10px;
  }

  .apostle-section {
    margin-top: 0px !important;
  }

  .apostl-img {
    width: 100%;
    margin: 15px 4px;
    border: solid 1px #9d9d9d;
    border-radius: 5px;
    padding-top: 25px;
  }

  .giveBtn {
    display: none;
  }

  .video_container {
    margin-top: 20px;
  }

  .home_banners {
    top: 113px;
    padding-bottom: 0px;
    height: calc(100% - 113px);

    .banner_mainHeading {
      font-size: 106px;
      margin: 10px 0px !important;
    }
  }

  .pad-top-100 {
    padding-top: 50px;
  }

  .top-space-100 {
    margin-top: 50px;
  }

  .event-bg {
    display: none;
  }

  .upcoming-section-box {
    padding: 0px;
    margin-top: 0px;
    h2 {
      color: #272c48 !important;
    }
  }

  .upcoming-event-section {
    padding: 0px 0px;

    .slick-next {
      right: -10px;
    }

    .slick-prev {
      left: -10px;
    }
  }

  .mobile-menu-button {
    margin-left: 20px;
  }

  .btn-hide {
    display: none;
  }

  .title-txts {
    font-size: 35px;
  }

  .contribution-section {
    margin-top: 110px !important;
    .contribution-bg {
      min-height: auto;
      padding: 0px 15px;

      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
    }
  }
  .mob-pads {
    margin: 10px 0px !important;
  }
}

@include media($mobile-landscape) {
  .banner_section {
    padding-top: 65px;
    border-radius: 0px 0px 0px 59px;
  }
  .app_header {
    height: 65px;
  }
  .mobile-banner {
    min-height: auto;
  }
  .upcoming-event-section {
    padding: 0px 15px !important;
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    background-size: 9px;
  }
  .m-top {
    margin: 10px 0px !important;
  }
  .event-silder-box {
    margin-top: 20px;
  }

  .apostle-section {
    .apostle-bg {
      padding: 0px 15px;
    }
  }
  .apostl-matter {
    width: 100%;
    padding: 0px 10px 25px;
  }
  .apostl-img {
    width: 100%;
    margin-top: 15px;
    border: solid 1px #666;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .mobile-menu-button {
    margin-right: 0px;
    margin-left: 0px;
    position: absolute;
    left: inherit;
    top: 11px;
    right: 0;
  }

  .divide-btns-bg {
    margin-bottom: -28px;
  }
  .event-silder-box {
    .slick-prev {
      left: -20px;top: 109px;
    }
    .slick-next {
      right: -20px;top: 109px;
    }
  }

  .journey-section {
    margin-top: 20px;
  }

  .home_banners {
    top: 0px;
    height: calc(100% - 0px);
  }

  .common_setion_gap {
    margin-bottom: 30px;
  }

  .ERP_img_col img {
    max-width: 280px;
  }

  .features_img_col {
    height: 150px;
    margin-top: 20px;
    display: none;
  }

  .features_box {
    position: relative;

    .btn {
      position: absolute;
      bottom: 30px;
    }
  }
  .tabContent_img img {
    max-height: 145px;
  }
  .slider_testimonial_bg_wrap {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .slider_testimonial .slick-arrow {
    bottom: -19px;
  }
  .logo_footer {
    img {
      max-width: 150px;
    }
  }
  .contact_form {
    padding: 35px 35px 35px;
    border-radius: 30px;
  }
  .Contact_section {
    border-radius: 60px 0px 0px 0;
  }
  .features_row > div {
    margin-bottom: 20px;
  }
  .features_icon_col {
    height: auto;
  }
  .features_icon_col img {
    max-height: 50px;
    max-width: 50px;
  }
  .testimonial_card .testimonial_quote img {
    max-width: 50px;
  }
  .testimonial_card .testimonial_quote {
    margin-top: -23px;
  }
  .features_row {
    padding-bottom: 20px;
    .slick-arrow {
      background-color: transparent;
      bottom: 0;
      background-size: 15px;
    }
    .slick-track {
      display: flex;
      .slick-slide {
        height: auto;
      }
      .features_box {
        height: 100%;
      }
    }
  }

  .tab_vertical_mbl_none {
    display: none !important;
  }
  .tabContent_responsive_accordian {
    padding: 0;
    box-shadow: none;
    border: none;
    display: flex;
    flex-direction: column;

    .tab_accordial_heading {
      display: block;
    }

    &.tab-content > .tab-pane {
      display: block !important;
      opacity: 1 !important;
      border-radius: 7px;
      overflow: hidden;

      &.selected {
        .tab_accordial_heading {
          border-radius: 7px 7px 0 0;
        }
      }
    }

    .tab_accordial_heading {
      color: #fff;
      padding: 15px;
      border-radius: 7px;
      background-size: 16px !important;
      margin-bottom: 2px;
      background: url(../img/down-arrow.svg) right 15px center no-repeat
        $color-secondary-dark;
    }
    .tabepane_row_content {
      display: none;
      box-shadow: 0px 3px 6px #00000029;
      padding: 15px;
      margin: -2px 0 20px 0;
      border: 1px solid #ddd;
      border-radius: 0 0 7px 7px;
    }
  }
  .tabContent_img {
    display: none;
  }
}

@include media($mobile-landscape) {
  .home_banners {
    top: 0px;
    padding-bottom: 0px;
    height: calc(100% - 0px);

    .banner_mainHeading_sub {
      font-size: 16px;
    }

    .banner_mainHeading {
      font-size: 70px;
      margin: 20px 0px 0px !important;
    }
  }

  .mobile-banner {
    .banner_wrap {
      img {
        height: 300px;
        object-fit: cover;
      }
    }
  }
  .banner_btn a {
    margin: 5px 3px;
  }
  .pad-top-100 {
    padding-top: 30px;
  }

  .slick-arrow {
    width: 34px;
    height: 34px;
    background-size: 8px !important;
  }
  .video_section_v1 {
    height: 330px;
    .imges-outer {
      height: 330px;
    }
  }
  .video_container {
    .slick-prev {
      left: -13px;
    }
    .slick-next {
      right: -13px;
    }
    .slick-arrow {
      top: 140px;
    }
  }

  .jounary-information {
    margin: 20px 0px 0px;
  }

  .apostle-section {
    margin-top: 0px !important;
  }

  .jounary-matter {
    h3 {
      font-size: 28px;
    }
  }
  .video-iconss {
    width: 165px;
  }
  .banner_btn a:not(:first-child) {
    margin-left: 10px;
  }
  .video_section_caption {
    max-width: calc(100% - 50px);
  }

  .jounary-information{
    .slick-arrow.slick-next{right: -15px;}
    .slick-arrow.slick-prev{left: -15px;}
  }
  .slick-arrow.slick-next{ right: -15px;}
  .slick-arrow.slick-prev{ left: -15px;}

  .banner_slider {
    .slick-arrow.slick-next{ right: 10px;}
    .slick-arrow.slick-prev{ left: 10px;}
  
  }
}

@media (max-width: 375px) {
  .home_banners {
    .banner_mainHeading {
      font-size: 54px;
      margin: 10px 0px 0px !important;
    }
  }
}
