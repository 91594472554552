.max-w-730px {
  max-width: 730px;
}
.max-w-920 {
  max-width: 920px;
}
.rounded-20px {
  border-radius: 20px;
}
.banner_section_inner {
  .line-height-100 {
    line-height: 80%;
  }
  .banner_contents {
    top: 350px;
  }

  .bannerImg_wrap {
    width: 100%;
    height: 715px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: cover;
      max-width: calc(100% + 30px);
      min-height: 100%;
      min-width: calc(100% + 30px);
      max-height: calc(100% + 30px);
      width: auto;
      height: auto !important;
    }
  }
}
.article_app_2 {
  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.KSF_history {
  margin-top: -140px;
  position: relative;
  z-index: 2;

  .KSF_history_content {
    padding-bottom: 340px;
    padding-top: 60px;
  }
}
.history_imgWrap {
  margin-top: -300px;
  position: relative;
  z-index: 3;
  img {
    max-width: 980px;
  }
}

.our_journy_section {
  min-height: 385px;
  padding-bottom: 100px;
  background-image: url("../img/bg-pattern-1.jpg");
  background-position: right center;
  background-repeat: no-repeat;
}

.OurMission_content {
  min-height: 496px;
  padding: 50px 0;
  background-image: url("../img/Mission-bg.jpg");
  background-position: left bottom;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    background-color: $color-secondary;

    position: absolute;
    right: -30%;
    top: 0;
    width: 82%;
    height: 100%;
    transform: skew(30deg, 0deg);
  }
}
.OurMission_logo {
  width: 178px;
  height: auto;
  max-width: 178px;
  min-width: 178px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
.Missions_2_img {
  width: 100%;
  height: 495px;
}
.Missions_2_article {
  padding: 40px 40px;
  margin-left: -175px;
}
.Missions_2_title {
  margin-top: -10px;
}
.mega_tent_img {
  width: 100%;
  height: 510px;
}
.MegaTent_ontent {
  margin-top: -100px;
}

/*Events Start*/
.events_slider {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  margin-top: -140px;
  margin-bottom: 50px;
  .slick-arrow.slick-prev {
    left: -30px;
  }
  .slick-arrow.slick-next {
    right: -30px;
  }
}
.events_slider_img {
  height: 307px;
  img {
    transition: all 0.4s ease;
  }
}
.announcements_coll {
  position: absolute;
  bottom: -18px;
  right: 18px;
  z-index: 2;
}
.announcements_col_wrap {
}
.announcements_num {
  width: 17px;
  height: 17px;
  background: #292929;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -5px;
  right: -5px;
}
.events_slider_item {
  padding: 30px;
}
.events_listBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s ease;

  &:hover {
    border-color: $color-primary;
    background-color: $color-secondary-light;
    .link-primary .svgFill {
      fill: $color-primary-dark;
    }
    .events_slider_img {
      img {
        transform: scale(1.2);
      }
    }
  }

  .events_slider_img {
    height: 230px;
  }
  .event_link {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.site-tab-solid {
  padding-bottom: 20px;
  .nav-link {
    font-size: 20px;
    font-family: $font-primary-bold;
    height: 55px;
    color: $color-default;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    position: relative;
    margin-right: 20px;

    svg {
      position: absolute;
      bottom: -14px;
      left: 0;
      right: 0;
      opacity: 0;
      margin: 0 auto;
    }
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: $color-primary;
    border-color: $color-primary;

    svg {
      opacity: 1;
    }
  }

  &.site-tab-solid-16px {
    .nav-link {
      font-size: 16px;
      svg {
        bottom: -11px;
      }
    }
  }
}

.btn-outline-grey-hover-primary {
  &:hover,
  &.active {
    background-color: $color-primary;
    border-color: $color-primary !important;

    svg {
      fill: #fff;
    }
  }
}

.offcanvas-body {
  border-top: 8px solid $color-primary;
}

.events_lg_img {
  height: 417px;
}
.event_content_wrap {
  margin-top: -70px;
}
.event_content {
  padding: 30px;
}
.imp_msg {
  background: #dff5ff;
  padding: 30px;
  border-radius: 10px;
}

.list_site_1 {
  li {
    position: relative;
    line-height: 140%;
    padding-left: 23px;
    padding-bottom: 10px;
    &::before {
      content: "";
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      color: #fe6b02;
      font-size: 12px;
      position: absolute;
      left: 0;
      top: 4px;
      background-image: url("../img/star.svg");
      background-position: right center;
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
    }
  }
}
.offcanvas .btn-close-design2 {
  top: 40px;
}

.max-760px {
  max-width: 760px;
}
.badge_1 {
  position: absolute;
  right: 30px;
  top: -15px;
}
/*Events End*/

.Ministries_list {
  padding: 0 80px;
}
.Ministries_list_img {
  height: 450px;
  position: relative;
  transition: all 0.4s ease;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    opacity: 0;
    transition: all 0.4s ease;
    z-index: 1;
  }

  img {
    transition: all 0.4s ease;
  }

  &:hover {
    &::before {
      opacity: 0.4;
    }

    .Ministries_list_caption {
      background-color: $color-secondary-light !important;
    }
    img {
      transform: scale(1.2);
    }
    .link-primary .svgFill {
      fill: $color-primary-dark;
    }
  }
}
.Ministries_list_caption {
  left: 25px;
  bottom: 25px;
  padding: 25px;
  position: relative;
  z-index: 2;
  right: 25px;
}

.devider_app {
  border: 1px solid #e1e1e1;
  position: relative;
  &::before,
  &::after {
    content: "";
    width: 9px;
    height: 9px;
    /* UI Properties */
    background: #e1e1e1;
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: -2px;
    top: -4px;
  }
  &::after {
    left: inherit;
    right: -1px;
  }
}

.Ministries_list_others_img {
  height: 189px;
  .Ministries_list_caption {
    left: 12px;
    bottom: 12px;
    padding: 12px;
    right: 12px;

    span:not(.link-primary) {
      position: relative;
      top: 1pxF;
    }

    svg {
      max-width: 16px;
      height: auto;
    }
  }

  position: relative;
  transition: all 0.4s ease;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    opacity: 0;
    transition: all 0.4s ease;
    z-index: 1;
  }

  img {
    transition: all 0.4s ease;
  }

  &:hover {
    &::before {
      opacity: 0.4;
    }

    .Ministries_list_caption {
      background-color: $color-secondary-light !important;
    }
    img {
      transform: scale(1.2);
    }
    .link-primary .svgFill {
      fill: $color-primary-dark;
    }
  }
}
.rounded-8px {
  border-radius: 8px;
}
.max-w-850px {
  max-width: 850px;
}
.max-w-1100px {
  max-width: 1100px;
}

.Ministries_list_others {
  .slick-arrow {
    width: 30px;
    background-size: 7px;
    height: 30px;
    top: calc(50% - 20px);
  }
  .slick-arrow.slick-prev {
    left: -14px;
  }
  .slick-arrow.slick-next {
    right: -14px;
  }
}

.events_photo_img {
  height: 200px;
}

.video_wrap_2 {
  height: 320px;
  background-color: $color-secondary;
  max-width: 1170px;
  width: 100%;
}

.Fellowship_img {
  height: 695px;
}

.Fellowship_article {
  padding: 35px;
  margin-left: -170px;
  position: relative;
}
.Fellowship_content {
  padding: 0 0px 0 135px;
}
.FellowshipFrame_img {
  width: 315px;
  height: 280px;
  position: absolute;
  bottom: 60px;
  padding: 15px;
  left: -133px;
  background-color: #fff;
}
.max-w-650 {
  max-width: 650px;
}
.form_container_wrap {
  padding: 45px;
}

.contact_icon {
}
.form_container_wrap {
}

.volunteer_img {
  img {
    max-width: 100%;
    max-height: 352px;
    width: auto;
    height: auto;
  }
}
.banner_contents,
#siteFooter {
  .btn-primary-hoverWhite,
  .btn-white-hoverWhite {
    svg {
      #Path_1 {
        fill: $color-primary;
      }
      #Path_2 {
        fill: #fff;
      }
    }

    &:hover,
    &:focus {
      background-color: $color-primary-dark !important;
      border-color: $color-primary-dark !important;
      color: #fff !important;

      svg {
        #Path_1 {
          fill: #fff;
        }
        #Path_2 {
          fill: $color-primary-dark;
        }
      }
    }
  }
}

.video_wrap_modal {
  max-width: 100%;
  max-height: 900px;
  height: calc(100vh - 100px);
  border: 1px solid #fff;
  background-color: #000;
}
.modal-dialog-popup {
  max-width: 1500px;
}
.event_content_wrap {
  padding-bottom: 0px !important;
}

.misionBanner01 {
}

.QuikLinks_cta {
  color: $color-secondary;
  padding: 15px;
  display: none;
  border-radius: 10px 10px 0 0;

  svg {
    max-width: 18px;
  }
}

.comingSoon {
  padding: 80px 0;
}
.comingSoon_img {
  img {
  }
}

// .modal-open {
//   &::after {
//     content: "";
//     position: absolute;
//   }
// }

.KSF_daily_services{
  h2{
    margin-bottom: 70px!important;
  }
  .KSF_service-card{
    width: 100%;
    height: 100%;
    padding: 70px 20px 30px 20px;
    h5{
      background-color: $color-primary;
      text-align: center;
      color: $color-white;
      font-size: 24px;
      width: calc(100% - 60px);
      padding: 20px;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 30px;
      transform: translateY(-50%);
      &:after{
        content: "";
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-top: 13px solid $color-primary;
        bottom: -12px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    p{
      text-align: center;
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.KSF_gallery{
  width: 100%;
  margin-top: 60px;
  .gallery-slider{
    .slick-slide{
      object-fit: cover;
      max-height: 400px;
      img{
        border-radius: 15px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.slick-lightbox{
  .slick-slider{
    height: 100%;
    .slick-list{
      height: 100%;
    .slick-track{
      height: 100%;
    }
    }
  }
  .slick-lightbox-close {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    &:before{
      font-size: 30px;
      opacity: 1;
      color: $color-black;
    }
    &:hover{
      background: $color-primary;
      &:before{
        color: $color-white;
      }
    }
}
} 

.KSF_clients-secction{
  .KSF_clients_slider{
    .single-client{
      border: 1px solid #e3e3e3;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

.MBCI_history{
  .card{
    height: 100%;
    padding: 30px;
    border-radius: 15px;
    .yearof{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      font-size: 22px;
      border-radius: 50%;
      background: $color-primary;
      border: solid 4px $color-white;
      -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      margin-top: -70px;
      margin-bottom: 30px;
      // font-family: $font-medium;
    }
    p{
      line-height: 27px;
    }
  }
}

.MBCI_radio_frequencies{
  .single_radio_frequencie{
    width: 100%;
    border: solid 1px $color-primary-light3;
    border-radius: 10px;
    .radio-station-name{
      width: 100%;
      color: $color-primary;
      padding: 20px 10px;
    }
    .country-name{
      width: 100%;
      padding: 15px 10px;
      border-radius: 0px 0px 10px 10px;
      background: $color-primary-light2;
    }
  }
}

/*Responsive Start*/
  @media (min-width: $xxl){
  .KSF_gallery .slick-prev {
    left: -80px;
}
  .KSF_gallery .slick-next {
    right: -80px;
  }
}

@include media($xxl) {
  .video_wrap_modal {
    max-height: 600px;
  }
  .modal-dialog-popup {
    max-width: 1080px;
  }
}

@include media($lg) {
  .banner_section_inner {
    .banner_contents {
      top: 300px;
    }

    .bannerImg_wrap {
      width: 100%;
      height: 500px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
        max-width: calc(100% + 30px);
        min-height: 100%;
        min-width: calc(100% + 30px);
        max-height: calc(100% + 30px);
        width: auto;
        height: auto !important;
      }
    }

    .banner_mainHeading {
      font-size: 106px;
    }
    .banner_btn {
      margin-top: 20px;
    }
    .banner_btn a:not(:first-child) {
      margin-left: 6px;
    }

    .btn {
      min-width: 80px;
      height: 44px;
      line-height: 44px;
      padding: 0 10px;
      font-size: 16px;
    }
  }

  .inner_page {
    .font-62px {
      font-size: 50px;
      margin-bottom: 6px;
    }
    .line-height-200 {
      line-height: 170%;
    }
  }
  .KSF_history .KSF_history_content {
    padding-bottom: 240px;
    padding-top: 30px;
  }
  .history_imgWrap {
    margin-top: -200px;
  }
  .history_imgWrap img {
    max-width: 680px;
  }

  .KSF_history {
    margin-top: -80px;
  }
  .article_app_2 p {
    margin-bottom: 10px;
  }
  .our_journy_section {
    background: none;
    min-height: inherit;
    padding-bottom: 0;
  }
  .common_setion_gap {
    margin-bottom: 70px;
  }
  .OurMission_content::after {
    right: 0;
    width: 100%;
    height: 100%;
    transform: none;
    opacity: 1;
  }
  .OurMission_content {
    min-height: 360px;
    padding-top: 0;
    padding-bottom: 25px;
  }
  .ourMissionContent_1 {
    padding-top: 15px;
  }
  .mega_tent_img {
    height: 400px;
  }

  .events_slider {
    margin-top: -100px;
    margin-bottom: 85px;
  }
  .events_slider .slick-arrow {
    bottom: -62px;
    top: inherit;
    width: 40px;
    height: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .events_slider .slick-arrow.slick-next {
    right: -65px;
  }
  .events_slider .slick-arrow.slick-prev {
    left: -65px;
  }

  .events_listBox {
    .events_slider_img {
      height: 180px;
    }

    .line-height-120 {
      line-height: 100%;
    }
    .line-height-180 {
      line-height: 160%;
    }
  }
  .site-tab-solid .nav-link {
    font-size: 16px;
    height: 44px;
    margin-right: 10px;
  }
  .site-tab-solid .nav-link svg {
    bottom: -11px;
    max-width: 20px;
  }

  .inner_page {
    .btn {
      min-width: 80px;
      height: 40px;
      line-height: 40px;
      padding: 0 13px;
      font-size: 14px;

      svg {
        max-width: 18px;
        height: auto;
      }
    }
  }

  .offcanvas.max-760px {
    max-width: 660px;
  }
  .Ministries_list {
    padding: 0;
  }
  .Ministries_list_img {
    height: 350px;
  }
  .Ministries_list_caption {
    left: 20px;
    bottom: 20px;
    padding: 20px;
    font-size: 20px;
    right: 20px;
    border-radius: 15px !important;

    svg {
      max-width: 24px;
      height: auto;
    }
  }

  .Other_Ministrires_row {
    .font-24px {
      font-size: 24px;
    }
    .Ministries_list_others_img {
      height: 229px;
    }
  }

  .offcanvas.max-w-1100px {
    max-width: 100%;
    border: none !important;
    .btn-close-design2 {
      top: 20px;
      left: inherit;
      right: 14px;
      z-index: 9;
      border: 1px solid #ccc;
      padding: 0;
      border-radius: 50%;
      width: 38px;
      height: 38px;

      span {
        display: none !important;
      }
      svg {
        margin: 0 !important;
      }
    }
  }
  .video_wrap_2 {
    height: 265px;
  }
  .Fellowship_content {
    padding: 0;
  }
  .FellowshipFrame_img {
    width: 250px;
    height: 180px;
    bottom: 40px;
    padding: 10px;
    left: -35px;
  }

  .video_wrap_modal {
    max-height: 500px;
  }
  .modal-dialog-popup {
    max-width: 780px;
  }
  .misionBanner01.rounded-20px {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0px !important;
  }

  .comingSoon {
    padding: 50px 0;
  }
  .comingSoon_img {
    img {
      max-width: 540px;
    }
  }
  .KSF_daily_services{
    .KSF_service-card{
      h5{
        font-size: 18px;
        padding: 15px;
      }
      p{
        font-size: 16px;
      }
    }
  }
}

@include media($md) {
  .KSF_history .KSF_history_content {
    padding-bottom: 140px;
    padding-top: 30px;
  }
  .history_imgWrap {
    margin-top: -100px;
  }
  .history_imgWrap img {
    max-width: 580px;
  }
  .common_setion_gap {
    margin-bottom: 60px;
  }
  .Missions_2 .font-48px {
    font-size: 31px;
  }
  .Ministries_list_img {
    height: 250px;
  }
  .Ministries_list_caption {
    left: 15px;
    bottom: 15px;
    padding: 11px 15px;
    font-size: 20px;
    right: 15px;
    border-radius: 15px !important;

    svg {
      max-width: 24px;
      height: auto;
    }
  }
  .offcanvas.max-w-850px {
    max-width: 630px;
  }
  .Other_Ministrires_row {
    .Ministries_list_caption {
      font-size: 16px;
    }
  }
  .video_wrap_2 {
    height: 200px;
  }
  .Fellowship_article {
    padding: 35px 35px 0;
    margin-left: auto;
    position: relative;
    margin-top: -80px;
    max-width: calc(100% - 60px);
    margin-right: auto;
  }
  .Fellowship_img {
    height: 495px;
  }
  .FellowshipFrame_img {
    width: 170px;
    height: 129px;
    bottom: 180px;
    padding: 8px;
    left: -35px;
  }
  .comingSoon_img {
    img {
      max-width: 450px;
      width: 100%;
    }
  }
   
  .MBCI_history{
    .card{
      .yearof{
        width: 70px;
        height: 70px;
        font-size: 18px;
      }
    }
  }
}
@include media($mobile-landscape) {
  .banner_section_inner {
    padding-top: 65px;

    .btn {
      min-width: inherit;
      height: 36px;
      line-height: 36px;
      padding: 0 10px;
      font-size: 14px;
    }

    .banner_mainHeading {
      font-size: 65px;
    }
    .banner_btn a {
      margin-bottom: 10px;
    }
  }
  .inner_page .font-62px {
    font-size: 30px;
    margin-bottom: 6px;
  }
  .article_app_2 p {
    margin-bottom: 10px;
    line-height: 150%;
  }
  .KSF_history .KSF_history_content {
    padding-bottom: 70px;
    padding-top: 20px;
  }
  .history_imgWrap {
    padding: 0 30px;
    img {
      max-width: 400px;
      width: 100%;
    }
  }
  .history_imgWrap {
    margin-top: -45px;
  }
  .common_setion_gap {
    margin-bottom: 30px;
  }
  .OurMission_logo {
    width: 95px;
    height: auto;
    max-width: 95px;
    min-width: 95px;
  }
  .OurMission_content {
    padding: 0px 0 20px;
  }
  .ourMissionContent_1 {
    padding-top: 0;
  }
  .Missions_2_img {
    height: 205px;
    margin-bottom: 25px;
  }
  .Missions_2_article {
    padding: 15px 0 0 0;
    margin-left: 0;
  }
  .mega_tent_img {
    height: 180px;
  }
  .MegaTent_ontent {
    margin-top: 20px;
  }
  .events_slider_item {
    padding: 20px;
  }
  .rounded-20px {
    border-radius: 15px !important;
  }
  .events_slider_img {
    height: 200px;
  }
  .site-tab-solid {
    padding-bottom: 12px;
  }
  .events_slider .slick-arrow {
    top: 95px;
    width: 34px;
    margin: 0;
    height: 34px;
  }
  .events_slider .slick-arrow.slick-next {
    right: -15px;
    left: inherit;
  }
  .events_slider .slick-arrow.slick-prev {
    left: -15px;
  }
  .events_slider {
    margin-bottom: 35px;
  }
  .font-18px {
    font-size: 16px !important;
  }
  .event_link {
    svg {
      max-width: 30px;
      height: auto;
    }
  }
  .event_content_wrap {
    margin-top: 0;
  }
  .event_content {
    padding: 20px 0;
  }
  .events_lg_img {
    height: 200px;
  }
  .offcanvas .btn-close-design2 {
    top: initial;
    z-index: 9;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: $color-secondary !important;
    svg {
      fill: #fff !important;
    }
  }
  .imp_msg {
    border-radius: 10px;
    margin-top: 17px;
  }
  .btn {
    font-size: 14px;
  }
  .Ministries_list_caption {
    font-size: 18px;
  }
  .offcanvas.max-w-1100px .btn-close-design2 {
    top: 16px;
    right: 8px;
    width: 30px;
    height: 30px;
  }
  .Fellowship_img {
    height: 285px;
  }
  .Fellowship_article {
    padding: 0;
    margin-top: 20px;
    margin-right: auto;
    max-width: 100%;
  }
  .FellowshipFrame_img {
    width: 125px;
    height: 91px;
    bottom: 17px;
    padding: 6px;
    left: -15px;
    position: absolute;
    margin-top: -50px;
  }
  .contact_icon {
    img {
      max-width: 40px;
    }
  }
  .form_container_wrap {
    padding: 25px;
  }
  .form_container {
    margin: 0 -25px;
  }
  .banner_section_inner .bannerImg_wrap {
    height: 400px;
  }
  .banner_section_inner .banner_contents {
    top: 200px;
  }
  .banner_section_inner .banner_btn {
    margin-top: 0;
  }

  .video_wrap_modal {
    max-height: 300px;
  }
  .modal-dialog-popup {
    max-width: 100%;
    margin-top: 0 !important;
    padding: 20px;
    margin: 0;
  }
  .events_photo_img {
    height: 90px;
    border-radius: 8px !important;
  }
  .video_section_content {
    .videoPoplink {
      svg {
        max-width: 100px;
      }
    }
  }

  .Give_btn,
  .VolunteerBtn {
    display: none !important;
  }
  .offcanvas-body {
    padding-bottom: 70px !important;
  }
  .badge_1 {
    position: absolute;
    right: 20px;
    top: -52px;
    border: 1px solid #fe6b02;

    &.bg-secondary {
      border-color: #fff;
    }
  }
  .KSF_history_ministry {
    margin-top: -47px;
  }
  .footer_link_row {
    display: none;
  }
  #siteFooter .social_links2 li a svg {
    max-width: 18px;
    max-height: 18px;
    width: auto;
    height: auto;
  }
  .QuikLinks_cta {
    display: block;
  }
  .comingSoon {
    padding: 20px 0;
  }
}
