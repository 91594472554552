body {
  font-family: $font-primary;
  color: $color-default;
  font-size: 16px;
  letter-spacing: 0.5px;
}
a {
  transition: all 0.3s ease;
}
.search_inline {
  overflow: hidden;
  box-shadow: 0px 2px 2px #00000024;
  border: 1px solid #ebebeb;
  border-radius: 262px;

  input,
  .input_text {
    border-radius: 262px;
    font-size: 18px;
    font-family: $font-medium;
  }
  .input_text {
    color: #00000099;
  }
}

.social_links {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social_iconList {
  display: flex;
  justify-content: center;
}
.social_iconList li {
  padding: 0 8px;
  a {
    svg {
      fill: $color-secondary;
    }

    &:hover {
      background-color: $color-secondary;
      border-color: $color-secondary !important;
      svg {
        fill: #fff;
      }
    }
  }
}

.page-wrapper {
  padding-top: 0px;
}
.content_inner_3 {
  max-width: 850px;
}

.banner_section_v2_wrap {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0px 3px 6px #0000001a;
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 0;
    background: #fff;
    box-shadow: 0px -4px 8px #0000001a;
    z-index: 2;
  }

  .banner_section_v2 {
    background: url(../img/banner_v2.jpg) right center no-repeat #fff;
    height: 540px;
    display: flex;
    align-items: center;
    max-width: 1580px;
    max-height: inherit !important;
    margin: 0 auto;
    width: calc(100% - 80px);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 30px;
    z-index: 5;
    padding-left: 30px;
    position: relative;
    /* margin-top: -60px; */
  }
}
.svg-icon-wrap {
  width: 0px;
  height: 0px;
  overflow: hidden;
  position: absolute;
}

.BackToTop {
  width: 44px !important;
  height: 44px !important;
  bottom: 20px;
  right: 20px;
  background: $color-secondary !important;
  box-shadow: 0 0 3px 0 #fff !important;
  border-radius: 50% !important;
  border: none !important;
  animation: uparrow 0.6s infinite alternate ease-in-out;
  opacity: 0;
  visibility: hidden;
}
#backtoTop.show {
  opacity: 1;
  visibility: visible;
}
@keyframes uparrow {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateY(-0.4em);
    opacity: 1;
  }
}

.banner_section_v2_wrap .banner_section_v2 {
  &.banner-innerPage.banner-innerPage-blog {
    background-image: url(../img/blog_bg.jpg);
    background-size: cover;
  }
}

.HomePageLink {
  position: fixed !important;
  left: 15px;
  top: 15px;

  svg {
    width: 38px;
    height: auto;
  }
}

.article_app{
  p{ margin-bottom:20px; line-height: 170%;}
}
.imges-outer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imges-outer img {
  object-fit: cover;
  max-width: calc(100% + 30px);
  min-height: 100%;
  min-width: calc(100% + 30px);
  max-height: calc(100% + 30px);
  width: auto;
  height: auto !important;
}

.video_wrap{ height: 670px; max-width: 1170px;box-shadow: 0px 3px 6px #00000029;
 overflow: hidden;}

 .text-silder-ellip {
  width: 100%;
  height: 78px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.follow-heads{
  padding: 15px 20px;
  background: #272c48;
  color: #fff;
  border-radius: 20px 20px 0px 0px;
}

.follow-list{
  width: 100%;
  ul{
    padding: 10px 10px 10px 20px;
    li{
      width: 100%;
      color: #272C48;
      padding: 5px 0px 5px 20px;
      font-size: 16px;
      position: relative;
      line-height: 22px;
      margin-bottom: 5px;

      &::before{
        content: "";
        left:0px;
        top:11px;
        position: absolute;
        background: url(../img/left-arrow.svg) left 0px no-repeat;
        width: 7px;
        height: 10px;
      }
    }
  }
}

/*Responsive Start*/
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1350px;
  }
}

@include media($xl) {
  .banner_section_v2_wrap .banner_section_v2 {
    padding-left: 20px;
    height: 500px;
    width: calc(100% - 60px);
  }
  .header_inner{
    .Applogo_col {
      img{
        max-width: 140px;
      }      
    }
  }

  .container {
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@include media($lg) {
  .header_inner{
    .Applogo_col {
      img{
        max-width: 100px;
      }      
    }
  }
  .page-wrapper-v2 {
    padding-top: 90px;
  }
  .video_wrap {
    height: 500px;
  }
}
@include media($md) {
  .video_wrap {
    height: 400px;
  }

  .header_inner{
    .Applogo_col {
      width: 100px;
      margin:inherit; 
      left:25px; 
      img{
        max-width: 115px;
      }         
    }
  }
}

@include media($mobile-landscape) {
  .header_inner{
    .Applogo_col {
      width: 60px;
      margin:inherit; 
      left:0px; 
      img{
        max-width: 115px;
      }         
    }
  }
  body {
    font-size: 16px;
  }
  .app_header .container .Applogo_col img {
    max-width: 44px;
  }
  .page-wrapper-v2 {
    padding-top: 120px;
  }
  .container {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .BackToTop {
    width: 34px !important;
    height: 34px !important;
    bottom: 9px;
    right: 15px;

    svg {
      width: 18px;
      height: auto;
    }
  }
  .HomePageLink {
    position: absolute !important;
    left: 0;
    top: 15px;
    right: 0;
    margin: 0 auto !important;
    width: 30px;
  }
  .loginPageHasBack{ padding-top:40px !important;}
  .HomePageLink svg {
    width: 30px;
  }
  .video_wrap {
    height: 178px;
  }
  .contact_col{
    img{ max-width: 34px;}
  }
  .follow-heads{    
    border-radius: 15px 15px 0px 0px;
  }
}
