:root,[data-bs-theme=light] {
    --bs-border-color:#E1E1E1;
}

$color-default:#272C48;
$color-default-light:#4A4A4A;
$color-primary: #FE6B02;
$color-primary-light: #F7FFE1;
$color-primary-light2: #FFF6EF;
$color-primary-light3: #F5DECD;
$color-primary-dark: #ca5806;
$color-secondary:#272C48;
$color-secondary-dark:#17376D;
$color-secondary-light:#fffbf0;
$color-light:#F5F5F5;
$color-grey: #7D7D7D;
$color-light-blue :#EBFCFF;
$color-danger:#FF0000;
$color-warn : #F39C12;

$color-green: #549E00;
$color-green-dark:#4D9212;
$color-green2:#3C9B4C;
$color-blue : #163C69;


$color-orange:#E36715;
$color-danger-light:#FFF3F3;
$color-white: #ffffff;
$color-off-white:#F5F5F5;
$color-black: #000000;

$color-grey1: #707070;
$color-grey-light:#E9E9E9;
$color-grey-light-2:#EBEBEB;
$color-7D7D7D : #7D7D7D;
$color-grey2: #999999;
$color-dashboard: #C3DEE6;
$color-footer:#89BECF;


$grey-light :#DBDBDB ;
$grey-light2 :#878787 ;



/*Breakpoints for responsive*/
$xxxs: '320px';
$xxs: '375px';
$xs: '480px';
$sm: '576px';
$mobile-landscape: '767px';
$md: '992px';
$lg: '1199px';
$xl: '1400px';
$xxl: '1440px'
